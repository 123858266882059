<template>
  <default-layout show-footer>
    <div class="p-4 text-xl">Câu hỏi về Interloan</div>
    <base-accordion />
  </default-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseAccordion from '@/components/Base/BaseAccordion.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export default defineComponent({
  name: 'DashboardMoreInfo',
  components: { DefaultLayout, BaseAccordion },
});
</script>
